import React, { Fragment } from 'react'

export default {
  INTERNAL_SERVER_ERROR:
    'Ein Fehler ist aufgetreten. Bitte zu einem späteren Zeitpunkt erneut versuchen.',

  SKIP_LINK_MAIN_CONTENT: 'Zum Hauptinhalt springen',

  HEADER_SEARCH: 'Suche',
  HEADER_ACCOUNT: 'Konto',
  HEADER_LOGIN: 'Anmelden',
  HEADER_CART: 'Warenkorb',
  HEADER_STOREFINDER: 'Store finden',
  HEADER_MENU_BUTTON: 'Menü',

  FOOTER_COPYRIGHT: (year, company) =>
    `Copyright ©${year} ${company}. Alle Rechte vorbehalten.`,

  SEARCH_CLOSE: 'Suche schließen',
  SEARCH_SUBMIT: 'Suchen',
  SEARCH_CLEAR: 'Zurücksetzen',
  SEARCH_RESULTS_CATEGORIES: 'Kategorien',
  SEARCH_RESULTS_PRODUCTS: 'Produkte',
  SEARCH_RESULTS_LINKS: 'Links',
  SEARCH_NO_RESULTS: 'Keine Suchergebnisse.',

  ACCOUNT_MENU_DASHBOARD: 'Meine Neuigkeiten',
  ACCOUNT_MENU_MY_INTERVALS: 'Mein Futterabo',
  ACCOUNT_MENU_INFORMATION: 'Meine persönlichen Daten',
  ACCOUNT_MENU_ORDERS: 'Meine Bestellungen',
  ACCOUNT_MENU_TO_CART: 'Zum Warenkorb',
  ACCOUNT_MENU_LOGOUT: 'Abmelden',
  ACCOUNT_MENU_CONTACT: 'Mein Kontakt & FAQ',
  HEADER_NAVIGATION: 'Navigation',
  HEADER_ACCOUNT_AREA: 'Mein Konto',
  NAVBAR_OVERVIEW: (item) => `Übersicht ${item}`,
  FLYOUT_MENU_CLOSE: 'Menü schließen',

  LOGINBOX_LOGIN: 'Anmelden',
  LOGINBOX_FORGOT_PASSWORD: 'Passwort vergessen',
  LOGINBOX_PASSWORD_RESET: 'Passwort zurücksetzen',
  LOGINBOX_ACTIVATE_CUSTOMER: 'Konto aktivieren',
  // eslint-disable-next-line react/display-name
  LOGINBOX_NO_ACCOUNT_YET: ({ Link, props }) => [
    'Noch kein Kundenkonto? ',
    <Link key="1" {...props}>
      Einfach registrieren
    </Link>,
  ],

  LOGINBOX_FORGOT_DESCRIPTION:
    'Bitte E-Mail Adresse angeben, um per E-Mail einen Link zu erhalten, mit dem das Passwort zurückgesetzt werden kann.',

  LOGINBOX_REGISTER: 'Registrieren',
  LOGINBOX_REGISTER_LICENCE: ({
    termsOfService: { Link: Link1, props: props1 },
    privacyPolicy: { Link: Link2, props: props2 },
  }) => [
    'Ich stimme den ',
    <Link1 key="1" {...props1}>
      AGB
    </Link1>,
    ' und den ',
    <Link2 key="3" {...props2}>
      Datenschutzbestimmungen
    </Link2>,
    ' zu',
  ],
  // eslint-disable-next-line react/display-name
  LOGINBOX_ALREADY_REGISTERED: ({ Link, props }) => [
    'Schon bei uns registriert? ',
    <Link key="1" {...props}>
      Jetzt anmelden!
    </Link>,
  ],
  LOGINBOX_REGISTER_ERROR_DISABLED_ACCOUNT:
    'Diese E-Mail-Adresse ist bereits mit einem deaktivierten Kundenkonto verknüpft. Um das Konto zu reaktivieren, bitte dem Bestätigungslink in der soeben versendeten E-Mail folgen.',

  LOGINBOX_PROCESSING:
    'Die Anfrage wird bearbeitet. Weitere Informationen per E-Mail.',
  LOGINBOX_SUBMIT: 'Absenden',
  // eslint-disable-next-line react/display-name
  LOGINBOX_BACK_TO_LOGIN: ({ Link, props }) => [
    <Link key="0" {...props}>
      Zurück zur Anmeldung
    </Link>,
  ],
  LOGINBOX_ERROR_EMAIL_EXISTS: 'Diese E-Mail ist bereits registriert!',
  // eslint-disable-next-line react/display-name
  LOGINBOX_ERROR_NO_EMAIL_EXISTS: ({ Link, props }) => [
    'Diese E-Mail ist nicht registriert. ',
    <Link key="1" {...props}>
      Jetzt registrieren!
    </Link>,
  ],
  LOGINBOX_ERROR_NO_LICENCE:
    'Allgemeine Geschäftsbedingungen sind erforderlich!',
  LOGINBOX_ERROR_WRONG_LOGIN: 'E-Mail oder Passwort ist falsch!',
  LOGINBOX_ERROR_CONFIRM:
    'Link in der Willkommens-E-Mail bestätigen, um fortzufahren.',
  ERROR_INVALID_CURRENT_PASSWORD: 'Falsches aktuelles Passwort eingegeben.',
  REQUEST_ERROR: 'Ein Fehler ist aufgetreten.',

  SORT_HEADING: 'Sortieren',
  SORT_OPTION_DEFAULT: 'Relevanz',
  SORT_OPTION_PRICE_ASC: 'Niedrigster Preis',
  SORT_OPTION_PRICE_DESC: 'Höchster Preis',
  SORT_OPTION_TITLE_DESC: 'Titel absteigend',
  SORT_OPTION_TITLE_ASC: 'Titel aufsteigend',
  PRODUCT_LIST_CURRENT_PAGE: (current, total) =>
    `Seite ${current} von ${total}`,
  PAGER_FIRST_PAGE: 'Erste Seite',
  PAGER_LAST_PAGE: 'Letzte Seite',
  PAGER_PREV_PAGE: 'Vorige Seite',
  PAGER_NEXT_PAGE: 'Nächste Seite',
  FILTER_HEADING: 'Filter',
  FILTER_ACTION: 'Filtern',
  MOBILE_FILTER_CLOSE: (totalProductCount) =>
    `${totalProductCount} Produkte anzeigen`,
  MOBILE_FILTER_SAVE: 'Speichern',
  MOBILE_FILTER_BACK: 'Zurück',
  FILTER_RESET_ALL: 'Zurücksetzen',
  FILTER_SHOW_PRODUCTS: 'Produkte anzeigen',
  PRODUCT_TILE_ADD_TO_CART: 'In den Warenkorb',
  PRODUCT_TILE_CHOSE_VARIANT: 'Variante wählen',
  PRODUCT_TILE_PREORDER: 'Vorbestellen',
  PRODUCT_TILE_TO_PRODUCT: 'Zum Produkt',
  PRODUCT_DETAIL_ADD_TO_CART: 'In den Warenkorb',
  PRODUCT_RATINGS_TITLE: 'Bewertungen',
  MORE_DETAILS: 'Mehr Details',
  LABEL_QUANTITY: 'Menge:',
  LABEL_PRODUCT_CONFIG_A: 'Größe: ',
  SELECT_BOX_DEFAULT_OPTION: 'Auswahl erforderlich',
  THIS_IS_A_REQUIRED_FIELD: 'Dies ist ein Pflichtfeld.',
  INVALID_EMAIL_ADDRESS: 'Gültige E-Mail-Adresse erforderlich.',

  ERROR_PAGE_TITLE: 'Ein Fehler is aufgetreten.',
  ERROR_PAGE_NOT_FOUND: 'Seite nicht gefunden!',
  BROWSER_HINT_PAGE_HEADING:
    'Leider gibt es ein Problem mit dem verwendeten Browser!',
  BROWSER_HINT_PAGE_SUB_HEADING:
    'Die Verwendung von Microsoft Internet Explorer wird leider nicht mehr unterstützt.',
  BROWSER_HINT_PAGE_TEXT_TOP:
    'Der verwendete Browser unterstützt aktuelle Technologien nicht in vollem Umfang. Wir empfehlen, auf einen neuen Browser umzusteigen.',
  BROWSER_HINT_PAGE_TEXT_BOTTOM: 'Bitte Browser auswählen.',
  BROWSER_HINT_PAGE_DOWNLOAD_HINT:
    'Aktuelle Browser-Version herunterladen (kostenfrei)',
  BROWSER_HINT_PAGE_RECOMMENDATIONS: 'Unsere Empfehlungen',

  DELETE_SLOT: 'Slot löschen',

  // eslint-disable-next-line react/display-name
  PRICE_HINT_WITH_BASE_PRICE: ({ Component, url, ...rest }) => [
    'inkl. MwSt., ggf. zzgl. ',
    <Component
      key={1}
      className="text-decoration-underline"
      href={url}
      {...rest}
    >
      Versand
    </Component>,
  ],
  BASE_PRICE_FORMAT: (price, unit) => `${price} / ${unit}`,
  PRICE_WITH_DISCOUNT: `Preis mit Gutschein:`,

  // eslint-disable-next-line react/display-name
  PRICE_HINT_WITH_PRICE: ({ Component, url, basePrice, suffix, ...rest }) => [
    `(${basePrice} / ${suffix} inkl. MwSt., ggf. zzgl. `,
    <Component
      key={1}
      className="text-decoration-underline"
      href={url}
      {...rest}
    >
      Versand
    </Component>,
    ')',
  ],
  DESCRIPTION: 'Beschreibung',
  INGREDIENTS: 'Inhaltsstoffe',
  COMPOSITION: 'Zusammensetzung',
  CONSTITUENTS: 'Analytische Bestandteile',
  ADDITIVES: 'Zusatzstoffe je kg',
  FEEDING_RECOMMENDATION: 'Fütterungsempfehlung',
  RATINGS: 'Bewertungen',
  LOADING_PRODUCT_RATINGS: 'Produktbewertungen werden geladen ...',
  PDP_PRODUCT_IMAGE_ALT: 'Produktbild',
  OUT_OF_STOCK: 'Ausverkauft',
  LABEL_PRODUCT_SIMILAR_PRODUCT: 'Sorte: ',

  SHOW_MORE: 'Mehr anzeigen',
  SHOW_LESS: 'Weniger anzeigen',
  MORE_VARIANTS: 'Weitere Varianten',
  THANK_YOU_FOR_ORDER: (customerName) =>
    `Vielen Dank für die Bestellung${customerName ? ', ' + customerName : ''}!`,
  CONFIRMATION_EMAIL: 'Bestätigungsmail wurde versendet.',
  TRACKER_LABEL: 'Jetzt sind wir dran!',
  ORDER_OVERVIEW: 'Bestellübersicht',
  PAYMENT_TYPE: 'Zahlart',
  SHIPPING_ADDRESS: 'Lieferadresse',
  CANCEL_ORDER: 'Bestellung stornieren',
  ABORT: 'Abbrechen',
  EDIT_ORDER: 'Bestellung bearbeiten',
  VERY_SATISFIED: 'sehr zufrieden',
  VERY_UNSATISFIED: 'sehr unzufrieden',
  SHIPMENT_LABEL: 'Versand',
  DISCOUNT_LABEL: 'Rabatt',
  TOTAL_LABEL: 'Summe',
  WRITE_REVIEW: 'Produkt bewerten',
  SUCCESS_EDIT_ORDER_NOT_POSSIBLE:
    'Eine nachträgliche Bearbeitung der Bestellung ist nicht möglich.',
  SUCCESS_CREATE_NEW_ORDER:
    'Bestellung jetzt stornieren und neue Bestellung aufgeben.',
  ORDER_CANCEL_SPELL_MESSAGE: 'Zu Stornierung und Neubestellung hier:',
  ERROR_IN_ORDER: 'Hat sich ein Fehler eingeschlichen?',
  VOUCHER_CODE: 'Gutscheincode:',
  SPECIAL_PRICE: (price) => `${price} Sonderpreis`,
  PDP_SUBSCRIPTION_TOAST_TITLE: 'Futterabo angepasst',
  PDP_SUBSCRIPTION_TOAST_TOTAL: (total) => [
    <span key={0}>Summe</span>,
    <span key={1} className="sr-only">
      :{' '}
    </span>,
    <span key={2}>{total}</span>,
  ],
  PDP_SUBSCRIPTION_TOAST_BUTTON_GOTO: 'Zum Futterabo',
  SUBSCRIPTION_PRICE_MIGHT_HAVE_UPDATED:
    'Bei Reaktivierung des Futterabos kann sich die Bestellsumme aktualisieren.',
  ORDER_PRODUCT_TABLE_COLUMN_ARTICLE: 'Artikel',
  ORDER_PRODUCT_TABLE_COLUMN_PRICE: 'Preis',
  PRICE: 'Preis',
  CONFIRM_ORDER: 'Bestellung bestätigen',
  BUY: 'KAUFEN',
  // eslint-disable-next-line react/display-name
  BUYBOX_DATAPOLICY: ({
    company,
    termsOfService: { Link: Link1, props: props1 },
    rightOfWithdrawal: { Link: Link2, props: props2 },
  }) => [
    'Es gelten die ',
    <Link1 key="0" {...props1}>
      AGB
    </Link1>,
    ` von ${company} auch hinsichtlich des `,
    <Link2 key="1" {...props2}>
      Widerrufsrechts
    </Link2>,
    '.',
  ],
  ARTICLE_OVERVIEW: 'Artikelübersicht',
  BACK: 'Zurück',

  FORM_LABEL_EMAIL_ADDRESS: 'E-Mail Adresse',
  FORM_LABEL_FIRST_NAME: 'Vorname',
  FORM_LABEL_LAST_NAME: 'Nachname',
  FORM_LABEL_COMPANY: 'Firma/Adresszusatz',
  FORM_SUBMIT: 'Absenden',
  FORM_SELECT_CHOOSE: 'Wählen',
  FORM_MULTISELECT_COUNT: (count) =>
    `${count} ${count === 1 ? 'Option' : 'Optionen'} gewählt`,
  FORM_ERROR_INVALID_NUMBER: 'Ungültige Zahl!',
  FORM_ERROR_NEGATIVE_NUMBER: 'Zahl muss positiv sein!',
  FORM_ERROR_INTEGER: 'Ganze Zahl benötigt!',
  FORM_HINT: 'Weitere Informationen',
  FORM_HINT_REQUIRED_FIELD: 'Pflichtfeld',
  FORM_LABEL_STREET_AND_NUMBER: 'Straße & Hausnummer',
  FORM_LABEL_POSTCODE: 'Postleitzahl',
  FORM_LABEL_CITY: 'Stadt',
  FORM_LABEL_COUNTRY: 'Land',
  FORM_CLEAR_DATE: 'Datum zurücksetzen',
  FORM_LABEL_PASSWORD: 'Passwort',
  FORM_LABEL_PASSWORD_CONFIRM: 'Passwort bestätigen',
  FORM_LABEL_PW_WEAK: 'zu kurz',
  FORM_LABEL_PW_MEDIUM: 'Mittel',
  FORM_LABEL_PW_STRONG: 'Stark',
  FORM_LABEL_PW_VERY_STRONG: 'Sehr stark',
  FORM_ERROR_REQ_FIRST_NAME: 'Vorname ist erforderlich!',
  FORM_ERROR_REQ_LAST_NAME: 'Nachname ist erforderlich!',
  FORM_ERROR_REQ_EMAIL: 'E-Mail Adresse wird benötigt!',
  FORM_ERROR_WRONG_EMAIL: 'Dies ist keine E-Mail Adresse!',
  FORM_ERROR_NO_PASSWORD: 'Passwort wird benötigt!',
  FORM_ERROR_SHORT_PASSWORD: 'Es werden 6 oder mehr Zeichen benötigt!',
  FORM_ERROR_LONG_PASSWORD: 'Max. 20 oder weniger Zeichen!',
  FORM_ERROR_NOT_SAME_PASSWORDS: 'Passwörter stimmen nicht überein!',
  FORM_ERROR_SAME_PASSWORDS: 'Altes und neues Passwort sind gleich!',
  FORM_ERROR_REQ_ADDRESS_CITY: 'Stadt wird benötigt!',
  FORM_ERROR_REQ_ADDRESS_STREET: 'Straße wird benötigt!',
  FORM_ERROR_REQ_ADDRESS_POSTCODE: 'Postleitzahl wird benötigt!',
  FORM_ERROR_REQ_ADDRESS_COUNTRY: 'Land wird benötigt!',

  PAYMENT_EDIT_ADDRESS_HEADLINE:
    'Die Adressdaten sind leider nicht vollständig',
  PAYMENT_EDIT_ADDRESS_INTRO:
    'Die Zahlung wurde bereits entgegengenommen. Bitte fehlende Adressdaten vervollständigen, damit die Bestellung vollständig bearbeitet werden kann. Bitte Seite nicht verlassen und Zahlvorgang nicht erneut durchführen, da dies zu mehrfachen Abbuchungen führen könnte.',
  PAYMENT_EDIT_ADDRESS_SUBMIT: 'Jetzt kaufen',
  PAYMENT_EDIT_ADDRESS_NAME: 'Vor- & Nachname',

  GRAND_TOTAL_WITH_TAX: [
    'Gesamtsumme',
    <span key="0" className="font-size-xxs font-weight-normal">
      {' '}
      (inkl. Steuern)
    </span>,
  ],
  SUBTOTAL: 'Zwischensumme',

  NEWSLETTER_SUBSCRIPTION_SUCCESS:
    'Vielen Dank für die Anmeldung. Bitte auf den Bestätigungslink in der E-Mail klicken, um die Registrierung abzuschließen.',
  NEWSLETTER_SUBSCRIPTION_ERROR: 'Es gibt ein Problem mit dem Abonnement.',
  NEWLSETTER_OPTOUT_BUTTON: 'Abmelden',
  NEWLSETTER_OPTOUT_ERROR: 'Es gibt ein Problem mit der Abmeldung.',
  NEWLSETTER_OPTOUT_SUCCESS: 'Abmeldung vom Newsletter war erfolgreich.',
  // eslint-disable-next-line react/display-name
  NEWSLETTER_PRIVACY_CHECKBOX: ({ Link, props }) => [
    'Ich habe die ',
    <Link key={0} {...props}>
      Datenschutzerklärung
    </Link>,
    ' gelesen und akzeptiere sie.',
  ],
  NEWSLETTER_INTEREST_CHECK_ERROR:
    'Bitte mindestens eine Tierart auswählen, um unseren Newsletter zu erhalten.',
  NEWSLETTER_PRIVACY_CHECK_ERROR:
    'Zustimmung zur Datenschutzerklärung ist erforderlich.',
  // eslint-disable-next-line react/display-name
  NEWSLETTER_PRIVACY_SETTINGS_ERROR: ({ Link, props }) => [
    'Bitte "reCAPTCHA" in den ',
    <Link key={0} {...props}>
      Privatsphäre-Einstellungen
    </Link>,
    ' aktivieren, um sich beim Newsletter anmelden zu können.',
  ],
  NEWSLETTER_INTEREST_DOG: 'Hund',
  NEWSLETTER_INTEREST_CAT: 'Katze',
  NEWSLETTER_DEFAULT_PLACEHOLDER: 'E-Mail-Adresse eintragen...',
  NEWSLETTER_NOT_SUPPORTED:
    'Der Newsletter wird von diesem Shop nicht unterstützt.',

  CUSTOMER_ACCOUNT_HEADING_OVERVIEW: 'Meine Neuigkeiten',
  CUSTOMER_ACCOUNT_HEADING_USERDATA: 'Meine persönlichen Daten',
  CUSTOMER_ACCOUNT_HEADING_EDIT: 'Kundenkonto bearbeiten',
  CUSTOMER_ACCOUNT_HEADING_ORDERS: 'Meine Bestellungen',
  CUSTOMER_ACCOUNT_HEADING_ORDER_OVERVIEW: 'Bestellübersicht',
  CUSTOMER_ACCOUNT_HEADING_REORDER: 'Produkte wieder bestellen',
  CUSTOMER_ACCOUNT_HEADING_SUBSCRIPTION: 'Futterabo',
  CUSTOMER_ACCOUNT_CONTACT: 'Mein Kontakt & FAQ',
  // eslint-disable-next-line react/display-name
  CUSTOMER_ACCOUNT_HEADING_ORDER_DETAILS: (orderId, status) => [
    `Bestellung Nr. ${orderId}`,
    status ? (
      <Fragment key={status}>
        {' - '}
        <small>
          <em>{status}</em>
        </small>
      </Fragment>
    ) : null,
  ],
  // eslint-disable-next-line react/display-name
  CUSTOMER_ACCOUNT_ELEMENT_COUNT: (count) =>
    `${count} ${count === 1 ? 'Element' : 'Elemente'}`,
  ORDER_ID: 'Bestellung #',
  DATE: 'Datum',
  SHIPPING_ADDRESSEE: 'Versand an',
  ORDER_SUM: 'Bestellsumme',
  ORDER_STATUS: 'Bestellstatus',
  COUNT_PER_PAGE: (count) => `${count} pro Seite`,
  LAST_ORDER_COUNT: (count) => `Letzte ${count} Bestellungen`,
  NO_ORDERS_YET: 'Es gibt noch keine Bestellungen.',
  ORDER_STATUS_PENDING: 'Ausstehend',
  ORDER_STATUS_CANCELABLE: 'Stornierbar',
  ORDER_STATUS_CANCELED_OR_CLOSED: 'Storniert',
  ORDER_STATUS_COMPLETE: 'Versand erfolgt',
  ORDER_STATUS_FRAUD: 'Betrugsverdacht',
  ORDER_STATUS_HOLDED: 'Angehalten',
  ORDER_STATUS_KLARNA_ACCEPTED: 'Klarna akzeptiert',
  ORDER_STATUS_KLARNA_DENIED: 'Klarna verweigert',
  ORDER_STATUS_KLARNA_PENDING: 'Klarna ausstehend',
  ORDER_STATUS_PAYMENT_REVIEW: 'Zahlungsprüfung',
  ORDER_STATUS_PENDING_PAYMENT: 'Zahlung ausstehend',
  ORDER_STATUS_PROCESSING: 'Wird gepackt',
  ORDER_ACTION_REORDER: 'Erneut bestellen',
  ORDER_ACTION_DETAILS: 'Details',
  ORDER_ACTION_CANCEL: 'Bestellung stornieren',
  SAVE: 'Speichern',
  CUSTOMER_ACCOUNT_CURRENT_PASSWORD: 'Aktuelles Passwort',
  CUSTOMER_ACCOUNT_NEW_PASSWORD: 'Neues Passwort',
  CUSTOMER_ACCOUNT_DATA: 'Meine Daten',
  CUSTOMER_DATA_SAVED: 'Kundenkonto aktualisiert',
  BIRTHDAY: 'Geburtstag',
  DAY: 'Tag',
  MONTH: 'Monat',
  YEAR: 'Jahr',
  INVALID_MONTH: 'Ungültiger Monat',
  INVALID_DAY: 'Ungültiger Tag',
  INVALID_DATE: 'Ungültiges Datum',
  DATE_IN_PAST: 'Datum liegt zu weit in der Vergangenheit',
  DATE_IN_FUTURE: 'Datum liegt zu weit in der Zukunft',
  CHANGE_PASSWORD: 'Passwort ändern',
  NEWSLETTER_SUBMIT: 'Anmelden',
  SUBSCRIBE: 'Anmelden',
  UNSUBSCRIBE: 'Abmelden',
  ORDER_DATE: 'Bestelldatum',
  ORDER_DETAILS_PAYMENT_TYPE: 'Zahlweise',
  ORDER_DETAILS_DOWNLOAD_DELIVERY_SLIP: (n) =>
    n === 1 ? 'Lieferschein herunterladen' : `Lieferschein ${n} herunterladen`,
  ORDER_DETAILS_DELIVERY_SLIPS: 'Lieferschein',
  ORDER_DETAILS_DELIVERY_TYPE: 'Versandart',
  ORDER_DETAILS_NO_DELIVERY_SLIPS: 'Bitte Kundendienst kontaktieren.',
  ORDER_DETAILS_SHIPMENT_TRACKING: 'Sendungsverfolgung',
  ORDER_DETAILS_INVOICE: 'Rechnung',
  ORDER_DETAILS_DOWNLOAD_INVOICE: 'Rechnung herunterladen',
  BILLING_ADDRESS: 'Rechnungsadresse',
  PRODUCT: 'Produkt',
  QUANTITY: 'Menge',
  BACK_TO_ORDER_LIST: 'Zurück zu meinen Bestellungen',
  NUM_ARTICLES: (count) => (count === 1 ? '1 Produkt' : `${count} Produkte`),
  PLEASE_LOG_IN: 'Eine Anmeldung ist erforderlich, um diese Seite anzusehen.',
  PAGE_N: (n) => `Seite ${n}`,
  PREVIOUS_SLIDE: 'Vorheriger Einschub',
  NEXT_SLIDE: 'Nächster Einschub',
  SLIDE_N: (n) => `Einschub ${n}`,

  PDP_ADD_TO_SUBSCRIPTION_POPUP_LABEL_NAME: (name) => `Name: ${name}`,
  PDP_ADD_TO_SUBSCRIPTION_POPUP_LABEL_STATUS: 'Status',
  PDP_ADD_TO_SUBSCRIPTION_POPUP_LABEL_INTERVAL: 'Intervall',
  PDP_ADD_TO_SUBSCRIPTION_POPUP_LABEL_SUM: 'Summe',
  PDP_ADD_TO_SUBSCRIPTION_POPUP_LABEL_ADJUSTMENT: 'Anpassung',

  ABO_LABEL_NAME: 'Name',
  ABO_LABEL_STATUS: 'Status',
  ABO_LABEL_INTERVAL: 'Intervall',
  ABO_LABEL_SUM: 'Summe',
  ABO_LABEL_ADJUSTMENT: 'Anpassung',
  ABO_STATUS_ACTIVE: 'Aktiv',
  ABO_STATUS_PAUSED: 'Pausiert',
  ABO_STATUS_PAUSED_UNTIL: 'Pausiert bis',
  ABO_STATUS_CANCELED: 'Beendet',
  WEEKS: (count) => `${count === 1 ? 'jede Woche' : `${count} Wochen`}`,
  ABO_IS_BEING_PACKED: 'Nicht Möglich',
  ABO_ADJUSTMENT_POSSIBLE: 'Möglich',
  ABO_DETAILS_MORE: 'Mehr',
  ABO_DETAILS_LESS: 'Weniger',
  ABO_DETAILS_SHOW_AND_EDIT: 'Details einsehen & bearbeiten',
  ABO_MY_REGULAR_ARTICLE: 'Meine Produkte',
  ABO_NO_PRODUCTS_FOUND: 'Keine Produkte gefunden',
  ABO_SET_NEXT_SHIPPING_DATE: 'Nächste Ausführung festlegen',
  ABO_ADJUST_DELIVERY_INTERVAL: 'Lieferintervall anpassen',
  ABO_CHANGE_STATUS: 'Status ändern',
  ABO_ADJUST_PRODUCTS: 'Produkte anpassen',
  ABO_ADJUST_DELIVERY_ADDRESS: 'Lieferadresse anpassen',
  ABO_ADJUST_NAME: 'Namen anpassen',
  ABO_DELIVERY_INTERVAL_OPTIONS: (count) =>
    `${count === 1 ? 'jede Woche' : `alle ${count} Wochen`}`,
  // eslint-disable-next-line react/display-name
  ABO_CURRENT_DELIVERY_INTERVAL: (value) => [
    'Aktuelles Lieferintervall: ',
    <strong key={value}>{value}</strong>,
  ],
  ABO_NEW_DELIVERY_INTERVAL: 'Neues Lieferintervall',
  // eslint-disable-next-line react/display-name
  ABO_CURRENT_NAME: (value) => [
    'Aktueller Name: ',
    <strong key={value}>{value}</strong>,
  ],
  ABO_BADGE_NEW: 'Neu',
  ABO_NEW_NAME: 'Neuer Name',
  // eslint-disable-next-line react/display-name
  ABO_CURRENT_DELIVERY_DATE: (value) => [
    'Aktuelle Ausführung: ',
    <strong key={value}>{value}</strong>,
  ],
  ABO_NEW_DELIVERY_DATE: 'Neue Ausführung',
  ABO_ERROR_PAUSE_DATE_MUST_BE_SET: 'Neues Ausführungsdatum wird benötigt.',
  ABO_ERROR_DATE_TOO_CLOSE:
    'Neues Ausführungsdatum muss mindestens 5 Tage in der Zukunft liegen.',
  ABO_ERROR_DATE_TOO_FAR: 'Spätester Liefertermin in 6 Monaten.',
  // eslint-disable-next-line react/display-name
  ABO_CURRENT_STATUS: (value) => [
    'Aktueller Status: ',
    <strong key={value}>{value}</strong>,
  ],
  ABO_NEW_STATUS: 'Neuer Status',
  ABO_POPUP_HEADING: 'Lieferdetails anpassen',
  ABO_POPUP_PRODUCTS_CHANGED:
    'Neue Gesamtsumme wird erst nach dem Speichern berechnet.',
  ABO_POPUP_PRODUCT_DELETE: 'Produkt entfernen',
  ABO_POPUP_PRODUCT_RESTORE: 'Produkt wiederherstellen',
  ABO_POPUP_PRODUCT_QTY: (qty) => `Menge: ${qty}`,
  ABO_POPUP_ERROR_NO_PRODUCTS:
    'Das Futterabo muss mindestens einen Artikel enthalten.',
  ABO_POPUP_CHANGE_PRODUCT_HEADLINE: 'Produkt anpassen',
  ABO_POPUP_CHANGE_PRODUCT_CTA: 'Anpassen',
  ABO_POPUP_ADD_PRODUCT_CTA: 'Produkt hinzufügen',
  ABO_POPUP_ADD_PRODUCT_TITLE: 'Produkt hinzufügen',
  ABO_NEXT_SHIPPING_ON: 'Nächste Ausführung am:',
  ABO_PAYMENT_TYPE: 'Zahlart:',
  ABO_SHIPPING_ADDRESS: 'Lieferadresse:',
  ABO_COMPOSITION: 'Zusammensetzung',
  ABO_CONSTITUENTS: 'Analytische Bestandteile',
  ABO_ADDITIVES: 'Zusatzstoffe je kg',
  ABO_NOT_AVAILABLE: 'Nicht mehr verfügbar',
  ABO_PDP_NEW_SUBSCRIPTION: 'Neues Futterabo',
  ABO_PDP_ADD_PRODUCT_TITLE: 'Produkt hinzufügen',
  ABO_PDP_SUBSCRIPTIONS: (cnt) => `Futterabo: ${cnt}`,
  ABO_PDP_NOT_EDITABLE:
    'Eine Änderung ist aktuell nicht möglich. Ihre nächste Lieferung ist in Bearbeitung.',
  ABO_PDP_NO_SUBSCRIPTIONS: 'Es gibt noch keine Abonnements.',
  ABO_PDP_PRODUCT_EXIST: ({ Link, props }) => [
    'Produkt ist bereits Bestandteil ',
    <Link key={0} {...props}>
      dieses
    </Link>,
    ' Futterabos.',
  ],
  ABO_SEARCH_PLACEHOLDER: 'Suche',
  ABO_SEARCH_SUBMIT: 'Suchen',
  ABO_LOADED_PRODUCTS: (count, total) => `${count} von ${total} Artikeln`,
  ABO_SHOW_MORE: 'Mehr anzeigen',
  ABO_NO_RESULTS: 'Keine Ergebnisse',

  ERROR_SUBSCRIBE: 'Es gab ein Problem mit der Anmeldung',
  ERROR_UNSUBSCRIBE: 'Es gab ein Problem mit der Abmeldung',

  SUCCESS_PAGE_TITLE: 'Vielen Dank für die Bestellung!',
  // eslint-disable-next-line react/display-name
  SUCCESS_PAGE_ORDER_NO: (orderNumber) => [
    <strong key={orderNumber}>Bestellnummer:</strong>,
    ' ',
    orderNumber,
  ],
  // eslint-disable-next-line react/display-name
  SUCCESS_PAGE_PAYMENT_METHOD: (paymentMethod) => [
    <strong key={paymentMethod}>Zahlart:</strong>,
    ' ',
    paymentMethod,
  ],
  SUCCESS_PAGE_SHIPPING_ADDRESS: 'Lieferadresse:',

  CART_HEADLINE: (count) => [
    'Warenkorb ',
    <span key={1} className="font-weight-normal">{`${count} Artikel`}</span>,
  ],
  CART_VIEW_CART: 'Zum Warenkorb',
  CART_SECTION_ITEMS: 'Artikel',
  CART_SECTION_DELIVERY: 'Lieferung',
  CART_SECTION_CHECKOUT: 'Bezahlen',
  CART_SHIPMENT_DELIVERY_INTERVAL: 'Lieferintervall',
  CART_DELIVERY_TIME: (...args) => [
    <strong key={1}>Lieferzeit:</strong>,
    ' ',
    ...args,
  ],
  CART_GRAND_TOTAL_WITH_TAX: [
    'Gesamtsumme',
    <span key="0" className="font-size-xxs font-weight-normal">
      {' '}
      (inkl. Steuern)
    </span>,
  ],
  CART_ERROR_INVALID_COUNTRY:
    'Leider wird das ausgewählte Land nicht von uns beliefert. Bitte eine andere Lieferadresse wählen.',

  COUPON_ACTION_TEXT_REMOVE: 'Stornieren',
  COUPON_ACTION_TEXT_APPLY: 'Einlösen',
  COUPON_ACTION_TITLE_REMOVE: 'Gutschein stornieren',
  COUPON_ACTION_TITLE_APPLY: 'Gutschein einlösen',
  COUPON_APPLIED: (couponCode) => `Gutschein ${couponCode} eingelöst!`,
  COUPON_CANCELED: 'Gutscheincode wurde storniert.',
  COUPON_FAILED: `Gutschein ungültig.`,
  COUPON_SECTION_HEADING: 'Gutschein',
  COUPON_CONSTRAINTS_HINT: 'Nur ein Gutschein pro Bestellung möglich.',
  REMOVE_ITEM: 'Artikel entfernen',
  GRAND_TOTAL: 'Gesamtsumme',
  DELIVERY_PLACE: 'Lieferort',
  DELIVERY_TIME: 'Lieferzeit',
  ABO_DELIVER_INTERVAL: (week) =>
    `${week === 1 ? 'jede Woche' : `Alle ${week} Wochen`}`,
  TO_CHECKOUT: 'Zur Kasse',
  PROCEED: 'Weiter',
  OR: 'oder',
  ENTER_COUPON: 'Gutscheincode',
  APPLIED_COUPON: 'Eingelöster Gutschein',
  NO_SHIPPING_AVAILABLE:
    'Das ausgewählte Land wird von uns noch nicht beliefert. Bitte eine andere Adresse wählen.',
  PRODUCT_OUT_OF_QTY: 'Dieses Produkt ist derzeit nicht ausreichend vorrätig.',

  CART_PAYMENT_METHOD_TITLE: 'Wir akzeptieren:',
  CART_CHECKOUT_PAYPAL_CLAIM: 'Direkt zu',
  CART_CHECKOUT_PAYPAL_LATER_CLAIM: 'Später Bezahlen',
  CART_PAYMENT_DELIVERY_INTERVAL: (...args) => ['Lieferinterval ', ...args],
  CART_PAYMENT_DELIVERY_INTERVAL_OPTIONS: (weeks) =>
    `${weeks === 1 ? 'jede Woche' : `alle ${weeks} Wochen`}`,

  ERROR_NO_SUBSCRIPTION: 'Es konnte kein aktives Futterabo gefunden werden.',
  PLEASE_SELECT_OPTION: 'Bitte wählen',
  BREADCRUMBS_NAV_LABEL: 'Brotkrümel',
  BREADCRUMBS_ROOT_ITEM_LABEL: 'Startseite',
  CUSTOMER_LOGOUT_HEAD: 'Die Abmeldung war erfolgreich.',
  CUSTOMER_LOGOUT_MESSAGE:
    'Die Abmeldung war erfolgreich. Weiterleitung zur Startseite in 5 Sekunden.',
  GOOD_MORNING: 'Guten Morgen',
  GOOD_DAY: 'Guten Tag',
  GOOD_EVENING: 'Guten Abend',
  NEXT_QUESTION: 'Weiter',
  PREV_QUESTION: 'Zurück',
  CLOSE_WINDOW: 'Schließen',
  SUB_CATEGORY_ALL_PRODUCTS: 'Alle Produkte',

  // All below translation belong to payment checkout process. keys will be in API response
  PROCEED_CHECKOUT_LOGIN_EMAIL:
    'Um zur Kasse zu gelangen, ist eine Anmeldung erforderlich.',
  UNABLE_TO_START_EC: 'Express Checkout kann nicht gestartet werden.',
  EC_ORDER_CANCELED: 'Express Checkout und Bestellung wurden storniert.',
  EC_CANCELED: 'Express Checkout wurde abgebrochen.',
  UNABLE_TO_CANCELED_EC: 'Express Checkout kann nicht abgebrochen werden.',
  UNABLE_TO_APPROVE_EC:
    'Express Checkout-Genehmigung kann nicht verarbeitet werden.',
  UNABLE_TO_INITIALIZE_EC_REVIEW:
    'Express Checkout-Überprüfung kann nicht initialisiert werden.',
  UNABLE_TO_UPDATE_SHIPPING: 'Versandart kann nicht aktualisiert werden.',
  EC_AGREEMENT_BEFORE_ORDER:
    'Allen Bedingungen zustimmen, um die Bestellung abzuschließen.',
  EC_ORDER_CONFIRMATION:
    'Die Bestellung ist eingegangen. Eine Bestätigungsmail wurde versendet.',
  EC_UNABLE_PLACE_ORDER: 'Die Bestellung kann nicht aufgegeben werden.',
  UNABLE_TO_INITIALIZE_EC: 'Express Checkout kann nicht initialisiert werden.',
  PAYPAL_EC_TOKEN_NOT_EXIST: 'PayPal Express Checkout Token existiert nicht.',
  PAYPAL_EC_WRONG_TOKEN: 'Falsches PayPal Express Checkout Token angegeben.',
  EC_PAYMENT_CANNOT_RETRIEVE:
    'Der Zahlungsmethodencode kann nicht abgerufen werden.',
  EC_PAYMENT_INFORMATION_CANNOT_RETRIEVE:
    'Die Instanz des Zahlungsinformationsobjekts kann nicht abgerufen werden.',
  PAYMENT_NOT_ALLOWED_FOR_BILLING_COUNTRY:
    'Die ausgewählte Zahlungsart ist für das Rechnungsland nicht zulässig.',
  ORDER_ACTION_NOT_AVAILABLE: 'Bestellaktion ist nicht verfügbar.',
  AUTHORIZE_NOT_AVAILABLE: 'Autorisierungsaktion ist nicht verfügbar.',
  CAPTURE_NOT_AVAILABLE: 'Capture-Aktion ist nicht verfügbar.',
  REFUND_NOT_AVAILABLE: 'Rückerstattungsaktion ist nicht verfügbar.',
  VOID_NOT_AVAILABLE: 'Ungültige Aktion ist nicht verfügbar.',
  REVIEW_NOT_AVAILABLE:
    'Die Aktion zur Zahlungsüberprüfung ist nicht verfügbar.',
  // payment checkout translations end

  EDIT_ADDRESS_ERROR_MISSING_NAME: 'Vor- & Nachname sind erforderlich!',
  EDIT_ADDRESS_ERROR_MISSING_STREET: 'Straße wird benötigt!',
  EDIT_ADDRESS_ERROR_MISSING_HOUSE_NUMBER: 'Eine Hausnummer ist erforderlich!',

  STOREFINDER_SELLERS: 'Händler und Vertriebspartner',
  STOREFINDER_ZIP_CITY: 'PLZ oder Stadt',
  STOREFINDER_ROUTE: 'Routenplaner',
  STOREFINDER_NO_RESULTS: 'Keine Ergebnisse',
  ADDED_TO_CART: 'Erfolgreich hinzugefügt!',
  NO_COMPONENT_AVAILABLE: 'Frontend-Komponente noch nicht implementiert',
  WRITE_YOUR_TEXT_HERE: 'Schreibe hier einen Text.',
  COPY_COUPON_LABEL: 'Gutscheincode',
  COPY_COUPON_COPY: 'Kopieren',
  COPY_COUPON_COPIED: 'Kopiert',
  TOPBAR_COPY_CODE: 'Code kopieren',
  TOPBAR_COPIED_CODE: 'Code kopiert',
  TOPBAR_CLOSE: 'Schließen',

  // social feeds
  SOCIAL_FEEDS_ERROR_INVALID_REQUEST: 'Ungültiger Instagram-Request.',
  SOCIAL_FEEDS_ERROR_REQUEST_LIMIT:
    'Instagrams Anfragen-Limit wurde erreicht. Bitte zu einem späteren Zeitpunkt noch einmal versuchen.',
  SOCIAL_FEEDS_ERROR_PERMISSION_DENIED: 'Zugriff auf Instagram verweigert.',
  SOCIAL_FEEDS_ERROR_NO_TOKEN: 'Instagram-Token ist nicht konfiguriert',
  SOCIAL_FEEDS_SHOW_MORE: 'Mehr anzeigen',

  LEADFORM_ERROR_STRING_MAX_100: 'Maximal 100 Zeichen',
  LEADFORM_API_ERROR:
    'Fehler beim Übermitteln des Formulars. Bitte zu einem späteren Zeitpunkt erneut versuchen.',

  // pali
  PALI_PLACEABLE_COMPONENTS: 'Platzierbare Komponenten',
  PALI_STATIC_COMPONENTS: 'Statische Komponenten',
  PALI_ATOMS_MOLECULES: 'Atome & Moleküle',
  PALI_BASICS: 'Basis',
  PALI_COLORS: 'Farben',
  PALI_TYPOGRAPHY: 'Typographie',
  PALI_ICONS: 'Icons',
  PALI_OPEN_IN_FULLSCREEN: 'Im Vollbild öffnen',

  PALI_SAMPLE_TEXT_LONG: 'Dies ist ein Beispieltext',
  PALI_SAMPLE_TEXT_SHORT: 'Beispieltext',
  PALI_SAMPLE_HEADING_LONG: 'Dies ist eine Überschrift',
  PALI_SAMPLE_HEADING_SHORT: 'Überschrift',
  PALI_SAMPLE_SUB_HEADING: 'Dies ist eine Unterüberschrift',
  PALI_SAMPLE_BUTTON: 'Button-Text',
  PALI_SAMPLE_CATEGORY: 'Beispielkategorie',
  PALI_SAMPLE_IMAGE_ALT: 'Alternativtext',
  PALI_SAMPLE_PRICE_FROM: 'ab 32,99 €',
  PALI_LOREM_IPSUM:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse nec mattis sem, eget fermentum lorem. Aenean venenatis eleifend ultrices. Suspendisse vel nisl libero. Vestibulum dapibus ultricies magna, nec luctus massa convallis vel. Etiam vel arcu et ex finibus condimentum et quis lorem. Vivamus ornare dignissim lacus, sit amet tempor nisl elementum non. Fusce vitae tellus lacus. Donec accumsan bibendum faucibus. Etiam magna erat, semper sed venenatis a, consequat volutpat turpis. Quisque ultricies leo ac tellus placerat, ac hendrerit lorem volutpat. Nullam quis mauris sollicitudin, finibus mauris id, elementum turpis. Etiam pulvinar tristique ligula sed ultrices. Vestibulum id leo id est varius tempor.',
  PALI_LOREM_IPSUM_TITLE: 'Lorem ipsum',
  PALI_LOREM_IPSUM_SHORT:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse nec mattis sem',
  PALI_LOREM_IPSUM_HTML:
    '<i class="font-weight-semi-bold">Lorem ipsum</i> dolor sit amet, consectetur adipiscing elit. Suspendisse nec mattis sem, eget <a href="#" class="text-primary text-underline">fermentum lorem</a>. Aenean venenatis eleifend ultrices. Suspendisse vel nisl libero. Vestibulum dapibus ultricies magna, nec luctus massa convallis vel. Etiam vel arcu et ex finibus condimentum et quis lorem. Vivamus ornare dignissim lacus, sit amet tempor nisl elementum non. Fusce vitae tellus lacus. Donec accumsan bibendum faucibus. Etiam magna erat, semper sed venenatis a, consequat volutpat turpis. Quisque ultricies leo ac tellus placerat, ac hendrerit lorem volutpat. "Nullam quis mauris sollicitudin, finibus mauris id, elementum turpis. Etiam pulvinar tristique ligula sed ultrices. <span class="d-block text-center">Vestibulum id leo id est varius tempor.</span>',

  PALI_VARIANT_DEFAULT: 'Default',

  PALI_ICON_LOADING: 'Laden',
  PALI_ICON_SUCCESS: 'Erfolgreich',
  PALI_ICON_ERROR: 'Fehler',
  PALI_ICON_ANIMATED: 'Animiert',

  PALI_BUTTON_STATE_DEFAULT: 'Default',
  PALI_BUTTON_STATE_LOADING: 'Ladeanimation',
  PALI_BUTTON_STATE_DISABLED: 'Deaktiviert',
  PALI_BUTTON_STYLE: (variant) => `Variante "${variant}"`,
  PALI_BUTTON_SIZE: (size) => `Größe "${size}"`,
  PALI_BUTTON_CONTENT_DEFAULT: 'Nur Text',
  PALI_BUTTON_CONTENT_ICON_LEFT: 'Text mit Icon links',
  PALI_BUTTON_CONTENT_ICON_RIGHT: 'Text mit Icon rechts',
  PALI_BUTTON_VARIANT: (dimension, state) => `${dimension} (${state})`,

  PALI_HEADER_LEFT_WHITE_MINI_SEARCHBAR_LESS_ICONS:
    'Logo links, weißer Hintergrund, eingeklappte Suche, minimale Icons ohne Text',
  PALI_HEADER_LEFT_WHITE_FULL_SEARCHBAR_MORE_ICONS:
    'Logo links, weißer Hintergrund, ausgeklappte Suche, alle Icons und mit Text',
  PALI_HEADER_LEFT_PRIMARY_FULL_SEARCHBAR_MORE_ICONS:
    'Logo links, primär-farbener Hintergrund, ausgeklappte Suche, alle Icons und mit Text',
  PALI_HEADER_CENTERED_WHITE_MINI_SEARCHWALL_LESS_ICONS:
    'Logo mittig, weißer Hintergrund, eingeklappte Suche, minimale Icons ohne Text',
  PALI_HEADER_CENTERED_WHITE_FULL_SEARCHWALL_MORE_ICONS:
    'Logo mittig, weißer Hintergrund, ausgeklappte Suche, alle Icons und mit Text',
  PALI_HEADER_CENTERED_PRIMARY_FULL_SEARCHWALL_MORE_ICONS:
    'Logo mittig, primär-farbener Hintergrund, ausgeklappte Suche, alle Icons und mit Text',

  PALI_SEARCH_BAR_ANGLED_SOLID:
    'Eckig, alle Buttons aktiv, gefüllter Submit-Button',
  PALI_SEARCH_BAR_ROUND_SOLID:
    'Rund, alle Buttons aktiv, gefüllter Submit-Button',
  PALI_SEARCH_BAR_ANGLED_TRANSPARENT:
    'Eckig, alle Buttons aktiv, transparenter Submit-Button',
  PALI_SEARCH_BAR_ROUND_TRANSPARENT:
    'Rund, alle Buttons aktiv, transparenter Submit-Button',
  PALI_SEARCH_BAR_ANGLED_WITHOUT_CLOSE:
    'Eckig, ohne Schließen-Button, transparenter Submit-Button',
  PALI_SEARCH_BAR_ANGLED_WITHOUT_CLOSE_WITHOUT_CLEAR:
    'Eckig, ohne Schließen-Button, ohne Reset-Button, transparenter Submit-Button',
  PALI_SEARCH_BAR_ANGLED_WITHOUT_SUBMIT_WITHOUT_CLOSE:
    'Eckig, ohne Schließen-Button, ohne Submit-Button',
  PALI_SEARCH_BAR_ANGLED_NO_BUTTONS: 'Eckig, ohne Buttons',
  PALI_SEARCH_BAR_DESCRIPTION:
    'Einen Suchtext eingeben, um den Reset-Button anzuzeigen',

  PALI_SEARCH_WALL_NO_LINKS: 'Keine Links',
  PALI_SEARCH_WALL_1_LINK: 'Ein Link',
  PALI_SEARCH_WALL_2_LINKS: 'Zwei Links',
  PALI_SEARCH_WALL_3_LINKS: 'Drei Links',
  PALI_SEARCH_WALL_4_LINKS: 'Vier Links',

  PALI_ALERTBOX_INFO: 'Info',
  PALI_ALERTBOX_INFO_MESSAGE: 'Info-Meldung',
  PALI_ALERTBOX_SUCCESS: 'Erfolg',
  PALI_ALERTBOX_SUCCESS_MESSAGE: 'Erfolgsmeldung',
  PALI_ALERTBOX_WARNING: 'Warnung',
  PALI_ALERTBOX_WARNING_MESSAGE: 'Warnungsmeldung',
  PALI_ALERTBOX_ERROR: 'Fehler',
  PALI_ALERTBOX_ERROR_MESSAGE: 'Fehlermeldung',
  PALI_ALERTBOX_INFO_PLAIN: 'Info-Meldung (schlicht)',
  PALI_ALERTBOX_SUCCESS_PLAIN: 'Erfolgsmeldung (schlicht)',
  PALI_ALERTBOX_WARNING_PLAIN: 'Warnungsmeldung (schlicht)',
  PALI_ALERTBOX_ERROR_PLAIN: 'Fehlermeldung (schlicht)',

  PALI_CTA_ONE_BUTTON_PRIMARY: 'Ein Button (Primärfarbe)',
  PALI_CTA_TWO_BUTTONS_SECONDARY: 'Zwei Buttons (Sekundärfarbe)',
  PALI_CTA_THREE_BUTTONS_OUTLINE_PRIMARY:
    'Drei Buttons (Rahmen mit Primärfarbe)',

  PALI_NEWSLETTER_MINIMAL:
    'Minimalkonfiguration: Datenschutz-URL, implizites Interesse',
  PALI_NEWSLETTER_INFOBOX_ROUND_ORDERED_SEPARATESUBMIT_EXTRAINTEREST:
    'Mit Infobox, runden Ecken, Platzhalter, geänderter Reihenfolge, separiertem Anmeldebutton und zusätzlichen Interessen',
  PALI_NEWSLETTER_INFOBOX_CENTERED_LOGOCOLOR_PRESELECTED:
    'Mit Infobox, zentriert, logofarbenem Hintergrund, und vorausgewähltem Interesse',

  PALI_STOREFINDER_WITH_CSV_SOURCE: 'Mit CSV Datenquelle',
  PALI_STOREFINDER_WITH_GEOJSON_SOURCE: 'Mit GeoJSON Datenquelle',

  PALI_DROPDOWN_RELEVANCE: 'Relevanz',
  PALI_DROPDOWN_LOWEST_PRICE: 'Niedrigster Preis',
  PALI_DROPDOWN_HIGHEST_PRICE: 'Höchster Preis',
  PALI_DROPDOWN_TITLE_DESCENDING: 'Titel absteigend',
  PALI_DROPDOWN_TITLE_ASCENDING: 'Titel aufsteigen',

  PALI_EXPANDABLE_BOX_TYPE_1: 'Typ 1, ohne Überschrift, Button am oberen Ende',
  PALI_EXPANDABLE_BOX_TYPE_2: 'Typ 2, mit Überschfit, Button am unteren Ende',

  PALI_FAQS_TWO_ITEMS:
    '2 Blöcke, ohne Akkordion-Icons, Farbe für Fragen und Antworten "primary-hover"',
  PALI_FAQS_THREE_ITEMS:
    '3 Blöcke, heller primärfarbener Hintergrund für Antworten',
  PALI_FAQS_FOUR_ITEMS:
    '4 Blöcke, Text für Fragen und Antworten in "logo-color", Icon-Farbe "dark", Überschriftenfarbe "primary-hover"',
  PALI_FAQS_QUESTION: 'Frage?',
  PALI_FAQS_ANSWER: 'Die Antwort ist 42!',

  PALI_FILTER_WIZARD_WITH_HEADING: 'Mit Überschrift',
  PALI_FILTER_WIZARD_WITH_CUSTOM_ANSWERS: 'Mit benutzerdefinierten Antworten',
  PALI_FILTER_WIZARD_DESCRIPTION:
    'Greift auf die Filter einer existierenden Kategorieseite zu.',
  PALI_FILTER_WIZARD_HEADING: 'Finde das passende Futter',
  PALI_FILTER_WIZARD_QUESTION_ANIMAL: 'Was haben Sie für ein Tier?',
  PALI_FILTER_WIZARD_QUESTION_AGE: 'Wie alt ist Ihr Tier?',
  PALI_FILTER_WIZARD_QUESTION_BRAND: 'Welche Marke bevorzugen Sie am meisten?',
  PALI_FILTER_WIZARD_CUSTOM_ANSWER: 'Benutzerdefinierte Antwort',

  PALI_IMAGE_MOBILE_AND_DESKTOP:
    'Unterschiedliche Bilder für Desktop und Mobile',
  PALI_IMAGE_MOBILE_ONLY: 'Bild für Mobile, gleiches Bild für Desktop',
  PALI_IMAGE_DESKTOP_ONLY: 'Bild für Desktop, gleiches Bild für Mobile',

  PALI_IMAGE_TEXT_OVERLAY_TOP_LEFT_LINK_BUTTON_WHITE_BACKGROUND:
    'Box oben links, Link-Button, weißer Hintergrund',
  PALI_IMAGE_TEXT_OVERLAY_TOP_RIGHT_LINK_BUTTON_PRIMARY_BACKGROUND:
    'Box oben rechts, Link-Button, Hintergrund Primary',
  PALI_IMAGE_TEXT_OVERLAY_BOTTOM_LEFT_SOLID_BUTTON_DARK_BACKGROUND:
    'Box unten links, gefüllter Button, dunkler Hintergrund',
  PALI_IMAGE_TEXT_OVERLAY_BOTTOM_LEFT_WITHOUT_BUTTON:
    'Box unten links, ohne Button',
  PALI_IMAGE_TEXT_OVERLAY_TOP_CENTER_WITHOUT_BUTTON:
    'Box oben mitte, ohne Button',
  PALI_IMAGE_TEXT_OVERLAY_RIGHT_CENTER_WITHOUT_BUTTON:
    'Box links mitte, ohne Button',
  PALI_IMAGE_TEXT_OVERLAY_BOTTOM_RIGHT_SOLID_BUTTON_DARK_TEXT_NO_GAP:
    'Box unten rechts, gefüllter Button, dunkler Text, ohne Lücke zwischen Über- und Unterüberschrift',
  PALI_IMAGE_TEXT_OVERLAY_CENTERED_SOLID_BUTTON_BOLD_HEADING_BUTTON_FIRST:
    'Box zentriert, gefüllter Button (oben), fette Überschrift, große Unterüberschrift',
  PALI_IMAGE_TEXT_OVERLAY_IMAGE_ONLY_WITH_IMAGE_LINK:
    'Bild mit Link über die gesamte Fläche',
  PALI_IMAGE_TEXT_OVERLAY_IMAGE_ONLY_WITHOUT_LINK: 'Bild ohne alles',

  PALI_LINE_SPACER_SM: 'Klein',
  PALI_LINE_SPACER_MD: 'Mittel',
  PALI_LINE_SPACER_LG: 'Groß',
  PALI_LINE_SPACER_XL: 'Größer',

  PALI_PRODUCT_SIMPLE_AVAILABLE: 'Einfaches Produkt (verfügbar)',
  PALI_PRODUCT_SIMPLE_NOT_AVAILABLE: 'Einfaches Produkt (nicht verfügbar)',
  PALI_PRODUCT_SIMPLE_NOT_AVAILABLE_NO_PREORDER:
    'Einfaches Produkt (nicht verfügbar, Vorbestellung nicht erlaubt)',
  PALI_PRODUCT_BUNDLE_FIXED_AVAILABLE: 'Bundle-Produkt (statisch, verfügbar)',
  PALI_PRODUCT_BUNDLE_DYNAMIC_AVAILABLE:
    'Bundle-Produkt (dynamisch, verfügbar)',

  PALI_STREAM_PLACEMENT_WITH_HEADING_WITH_TEXT: 'Mit Überschrift, mit Text',
  PALI_STREAM_PLACEMENT_WITH_HEADING_WITHOUT_TEXT: 'Mit Überschrift, ohne Text',
  PALI_STREAM_PLACEMENT_WITHOUT_HEADING_WITH_TEXT: 'Ohne Überschrift, mit Text',
  PALI_STREAM_PLACEMENT_WITHOUT_HEADING_WITHOUT_TEXT:
    'Ohne Überschrift, ohne Text',

  PALI_TEASER_HERO_LIGHT_BOX_LINK_BUTTON_WITH_IMAGE_LINK:
    'Helle Box, dunkler Text, Link-Button, mit Bildlink',
  PALI_TEASER_HERO_DARK_BOX_LINK_BUTTON_WITHOUT_HEADING_WITH_IMAGE_LINK:
    'Dunkle Box, heller Text, Link-Button, ohne Überschrift, mit Bildlink',
  PALI_TEASER_HERO_DARK_BOX_SOLID_BUTTON_WITHOUT_IMAGE_LINK:
    'Dunkle Box, heller Text, gefüllter Button, ohne Bildlink',
  PALI_TEASER_HERO_DARK_BOX_WITHOUT_BUTTON_WITH_IMAGE_LINK:
    'Dunkle Box, heller Text, ohne Button, mit Bildlink',
  PALI_TEASER_HERO_WITHOUT_BOX_WITH_IMAGE_LINK:
    'Ohne Box, heller Text, mit Bildlink',
  PALI_TEASER_HERO_IMAGE_ONLY_WITH_IMAGE_LINK: 'Bild mit Link',

  PALI_COLUMN_BASED_2_CARDS_WIDE:
    '2-spaltig, mit Überschrift, mit Text, mit Bild, mit breitem Button unter dem Bild',
  PALI_COLUMN_BASED_2_CARDS_WITH_TEXT_CENTERED:
    '2-spaltig, mit Text (zentriert), mit schmalem Button unter dem Text',
  PALI_COLUMN_BASED_2_CARDS_WITH_HEADING_WITH_TEXT_LEFT:
    '2-spaltig, mit Überschrift, mit Text (linksbündig)',
  PALI_COLUMN_BASED_3_CARDS_WITH_IMAGE_WITH_BUTTON_WIDE:
    '3-spaltig, mit Bild, mit breitem Button',
  PALI_COLUMN_BASED_3_CARDS_WITH_TEXT_WITH_IMAGE_WITH_BUTTON_WIDE:
    '3-spaltig, mit Überschrift, mit Text, mit Bild, mit schmalem Button unter dem Text',
  PALI_COLUMN_BASED_4_CARDS_WITH_HEADING_WITH_TEXT_WITH_IMAGE:
    '4-spaltig, mit Überschrift, mit Text, mit Bild',
  PALI_COLUMN_BASED_6_CARDS_WITH_HEADING_WITH_TEXT_WITH_IMAGE_WITH_BUTTON_WIDE:
    '6-spaltig, mit Überschrift, mit Text, mit Bild, mit breitem und gerahmten Button unter dem Bild',
  PALI_COLUMN_BASED_6_CARDS_3_ENTRIES: '6-spaltig mit 3 Einträgen',
  PALI_COLUMN_BASED_6_CARDS_WITH_BUTTON_WIDE:
    '6-spaltig, nur mit breiten Buttons',

  PALI_3_COLUMN_INFO_CARDS_ALIGN_LEFT: 'Linksbündiger Text',
  PALI_3_COLUMN_INFO_CARDS_ALIGN_CENTER: 'Zentrierter Text',

  PALI_2_COLUMN_CARDS_BG_WITH_HEADING_WITH_TEXT_WITH_BUTTON_ICON:
    'Mit Überschrift, mit Text, mit Button-Icon',
  PALI_2_COLUMN_CARDS_BG_WITH_HEADING_WITH_TEXT_WITHOUT_BUTTON_ICON:
    'Mit Überschrift, mit Text, ohne Button-Icon',
  PALI_2_COLUMN_CARDS_BG_WITH_HEADING_WITH_TEXT_WITHOUT_BUTTON:
    'Mit Überschrift, mit Text, ohne Button',
  PALI_2_COLUMN_CARDS_BG_WITH_HEADING_WITHOUT_TEXT_WITHOUT_BUTTON:
    'Mit Überschrift, ohne Text, ohne Button',
  PALI_2_COLUMN_CARDS_BG_WITHOUT_HEADING_WITH_TEXT_WITHOUT_BUTTON:
    'Ohne Überschrift, mit Text, ohne Button',

  PALI_2_COLUMN_IMAGETEXT_IMAGE_LEFT_TEXT_RIGHT: 'Bild links, Text rechts',
  PALI_2_COLUMN_IMAGETEXT_IMAGE_RIGHT_TEXT_LEFT: 'Bild rechts, Text links',

  PALI_VIDEO_WITH_HEADING_WITH_TEXT: 'Mit Überschrift, mit Text',
  PALI_VIDEO_WITHOUT_HEADING_WITHOUT_TEXT: 'Ohne Überschrift, ohne Text',

  PALI_WELCOME_BLOCK_DESCRIPTION: 'Speziell für die Startseite',

  PALI_TOGGLE_SWITCH_ERROR_MESSAGE: 'Fehlermeldung',
  PALI_TOGGLE_SWITCH_LABEL_CHECKED: 'Aktivieren',
  PALI_TOGGLE_SWITCH_LABEL_UNCHECKED: 'Deaktivieren',
  PALI_TOGGLE_SWITCH_VARIANT_LABELS_DEFAULT_CHECKED: 'Mit Labels, ausgewählt',
  PALI_TOGGLE_SWITCH_VARIANT_LABELS_DEFAULT_CHECKED_WITH_ERROR:
    'Mit Labels, ausgewählt, mit Fehlermeldung',
  PALI_TOGGLE_SWITCH_VARIANT_LABELS_DEFAULT_CHECKED_DISABLED:
    'Mit Labels, ausgewählt (deaktiviert)',
  PALI_TOGGLE_SWITCH_VARIANT_LABELS_DEFAULT_UNCHECKED: 'Mit Labels, abgewählt',
  PALI_TOGGLE_SWITCH_VARIANT_LABELS_DEFAULT_UNCHECKED_DISABLED:
    'Mit Labels, abgewählt (deaktiviert)',
  PALI_TOGGLE_SWITCH_VARIANT_LABELS_ALTERNATE_CHECKED:
    'Mit Labels, alternatives Farbschema, ausgewählt',
  PALI_TOGGLE_SWITCH_VARIANT_LABELS_ALTERNATE_CHECKED_DISABLED:
    'Mit Labels, alternatives Farbschema, ausgewählt (deaktiviert)',
  PALI_TOGGLE_SWITCH_VARIANT_LABELS_ALTERNATE_UNCHECKED:
    'Mit Labels, alternatives Farbschema, abgewählt',
  PALI_TOGGLE_SWITCH_VARIANT_LABELS_ALTERNATE_UNCHECKED_DISABLED:
    'Mit Labels, alternatives Farbschema, abgewählt (deaktiviert)',
  PALI_TOGGLE_SWITCH_VARIANT_NO_LABELS_DEFAULT_CHECKED:
    'Ohne Labels, ausgewählt',
  PALI_TOGGLE_SWITCH_VARIANT_NO_LABELS_DEFAULT_CHECKED_DISABLED:
    'Ohne Labels, ausgewählt (deaktiviert)',
  PALI_TOGGLE_SWITCH_VARIANT_NO_LABELS_DEFAULT_UNCHECKED:
    'Ohne Labels, abgewählt',
  PALI_TOGGLE_SWITCH_VARIANT_NO_LABELS_DEFAULT_UNCHECKED_DISABLED:
    'Ohne Labels, abgewählt (deaktiviert)',
  PALI_TOGGLE_SWITCH_VARIANT_NO_LABELS_ALTERNATE_CHECKED:
    'Ohne Labels, alternatives Farbschema, ausgewählt',
  PALI_TOGGLE_SWITCH_VARIANT_NO_LABELS_ALTERNATE_CHECKED_DISABLED:
    'Ohne Labels, alternatives Farbschema, ausgewählt (deaktiviert)',
  PALI_TOGGLE_SWITCH_VARIANT_NO_LABELS_ALTERNATE_UNCHECKED:
    'Ohne Labels, alternatives Farbschema, abgewählt',
  PALI_TOGGLE_SWITCH_VARIANT_NO_LABELS_ALTERNATE_UNCHECKED_DISABLED:
    'Ohne Labels, alternatives Farbschema, abgewählt (deaktiviert)',

  PALI_DATE_INPUT_EMPTY: 'Leer',
  PALI_DATE_INPUT_DEFAULT_VALUE: 'Vorausgefüllt',
  PALI_DATE_INPUT_DEFAULT_VALUE_DISABLED: 'Vorausgefüllt, deaktiviert',
  PALI_DATE_INPUT_WITH_ERROR: 'Mit Fehlermeldung',

  PALI_INPUT_EMPTY: 'Leer',
  PALI_INPUT_EMPTY_WITH_LABEL: 'Leer, mit Label',
  PALI_INPUT_EMPTY_WITH_PLACEHOLDER: 'Leer, mit Platzhalter',
  PALI_INPUT_WITH_LABEL_WITH_DEFAULT_VALUE: 'Mit Label, vorausgefüllt',
  PALI_INPUT_WITH_LABEL_WITH_PLACEHOLDER: 'Mit Label, mit Platzhalter',
  PALI_INPUT_WITH_LABEL_WITH_DEFAULT_VALUE_DISABLED:
    'Mit Label, vorausgefüllt, deaktiviert',
  PALI_INPUT_WITH_LABEL_WITH_PLACEHOLDER_DISABLED:
    'Mit Label, mit Platzhalter, deaktiviert',
  PALI_INPUT_WITH_LABEL_WITH_ERROR: 'Mit Label, mit Fehlermeldung',
  PALI_INPUT_WITH_LABEL_WITH_DEFAULT_VALUE_WITH_ERROR:
    'Mit Label, vorausgefüllt, mit Fehlermeldung',
  PALI_INPUT_PLACEHOLDER: 'Platzhalter',
  PALI_INPUT_ERROR_MESSAGE: 'Fehlermeldung',

  PALI_PASSWORD_EMPTY: 'Leer',
  PALI_PASSWORD_DEFAULT_VALUE: 'Vorausgefüllt',
  PALI_PASSWORD_CUSTOM_LABEL: 'Benutzerdefiniertes Label',
  PALI_PASSWORD_DISABLED: 'Deaktiviert',
  PALI_PASSWORD_WITH_ERROR: 'Mit Fehlermeldung',
  PALI_PASSWORD_WITH_CONFIRM_PASSWORD: 'Mit Passwortbestätigung',
  PALI_PASSWORD_WITH_CONFIRM_PASSWORD_DESCRIPTION:
    'Geben Sie das korrekte Passwort in das Bestätigungsfeld ein, um die Fehlermeldung verschwinden zu lassen. (Hinweis: Klicken Sie auf das Augensymbol, um das korrekte Passwort herauszufinden ;-) )',
  PALI_PASSWORD_WITH_STRENGTH_INDICATOR: 'Mit Stärkeindikator',
  PALI_PASSWORD_WITH_STRENGTH_INDICATOR_DESCRIPTION:
    'Geben Sie unterschiedlichen Text ein, um zu sehen, wie sich die Stärke eines Passworts ändert.',
  PALI_PASSWORD_ERROR_MESSAGE: 'Fehlermeldung',

  PALI_RADIO_BASIC: 'Einfach',
  PALI_RADIO_BUTTON_1: 'Radio-Button 1',
  PALI_RADIO_BUTTON_2: 'Radio-Button 2',
  PALI_RADIO_WITH_LABEL: 'Mit Label',
  PALI_RADIO_NO_INITIAL_SELECTION: 'Ohne Vorauswahl',
  PALI_RADIO_WITH_ERROR: 'Mit Fehlermeldung',
  PALI_RADIO_SINGLE_DISABLED_OPTION: 'Eine Option deaktiviert',
  PALI_RADIO_ALL_OPTIONS_DISABLED: 'Alle Optionen deaktiviert',
  PALI_RADIO_CUSTOM_CHILD_ELEMENTS: 'Mit Unterelement beim Aufklappen',
  PALI_RADIO_ERROR_MESSAGE: 'Fehlermeldung',

  PALI_SELECTBOX_DEFAULT: 'Default',
  PALI_SELECTBOX_PRESELECTED: 'Vorausgewählt',
  PALI_SELECTBOX_WITH_LABEL: 'Mit Label',
  PALI_SELECTBOX_DISABLED: 'Deaktiviert',
  PALI_SELECTBOX_WITH_ERROR: 'Mit Fehlermeldung',
  PALI_SELECTBOX_OPTION_1: 'Eine Option',
  PALI_SELECTBOX_OPTION_2: 'Eine andere Option',
  PALI_SELECTBOX_ERROR_MESSAGE: 'Fehlermeldung',

  PALI_CHECKBOX_CHECKED: 'Ausgewählt',
  PALI_CHECKBOX_UNCHECKED: 'Abgewählt',
  PALI_CHECKBOX_CHECKED_DISABLED: 'Ausgewählt (deaktiviert)',
  PALI_CHECKBOX_UNCHECKED_DISABLED: 'Abgewählt (deaktiviert)',
  PALI_CHECKBOX_UNCHECKED_WITH_ERROR: 'Abgewählt mit Fehlermeldung',

  PALI_MULTI_CHECKBOX_DEFAULT: 'Default',
  PALI_MULTI_CHECKBOX_PRESELECTED: 'Vorausgewählt',
  PALI_MULTI_CHECKBOX_WITH_LABEL: 'Mit Label',
  PALI_MULTI_CHECKBOX_DISABLED: 'Vollständig deaktiviert',
  PALI_MULTI_CHECKBOX_DISABLED_OPTION: 'Teilweise deaktiviert',
  PALI_MULTI_CHECKBOX_WITH_ERROR: 'Mit Fehlermeldung',
  PALI_MULTI_CHECKBOX_CUSTOM: 'Benutzerdefiniert',
  PALI_MULTI_CHECKBOX_OPTION_1: 'Eine Option',
  PALI_MULTI_CHECKBOX_OPTION_2: 'Eine andere Option',
  PALI_MULTI_CHECKBOX_ERROR_MESSAGE: 'Fehlermeldung',

  PALI_CUSTOM_SINGLE_SELECT_MINIMAL: 'Einzelauswahl',
  PALI_CUSTOM_SINGLE_SELECT_WITH_LABEL: 'Einzelauswahl mit Label',
  PALI_CUSTOM_SINGLE_SELECT_WITH_LABEL_PRESELECTED:
    'Einzelauswahl mit Label (vorausgewählt)',
  PALI_CUSTOM_SINGLE_SELECT_WITH_LABEL_DISABLED:
    'Einzelauswahl mit Label (deaktiviert)',
  PALI_CUSTOM_SINGLE_SELECT_WITH_LABEL_ERROR:
    'Einzelauswahl mit Label (fehlerhaft)',
  PALI_CUSTOM_SINGLE_SELECT_WITH_PLACEHOLDER: 'Einzelauswahl mit Platzhalter',
  PALI_CUSTOM_SINGLE_SELECT_WITH_LABEL_AND_PLACEHOLDER:
    'Einzelauswahl mit Label und Platzhalter',
  PALI_CUSTOM_SINGLE_SELECT_ALIGN_RIGHT:
    'Einzelauswahl mit rechtsbündigen Optionen',
  PALI_CUSTOM_SINGLE_SELECT_ALTERNATE_ICON:
    'Einzelauswahl mit alternativem Icon',
  PALI_CUSTOM_SINGLE_SELECT_WITH_CUSTOM_LABEL:
    'Einzelauswahl mit benutzerdefiniertem Label',
  PALI_CUSTOM_SINGLE_SELECT_WITH_CUSTOM_OPTIONS:
    'Einzelauswahl mit benutzerdefinierten Optionen',
  PALI_CUSTOM_MULTI_SELECT_WITH_LABEL: 'Mehrfachauswahl mit Label',
  PALI_CUSTOM_MULTI_SELECT_WITH_LABEL_PRESELECTED:
    'Mehrfachauswahl mit Label (vorausgewählt)',
  PALI_CUSTOM_MULTI_SELECT_WITH_CUSTOM_LABEL:
    'Mehrfachauswahl mit benutzerdefiniertem Label',

  PALI_CART_BUY_BUTTONS: (items) => ['Verfügbare Zahlungsmethoden: ', items],
  PALI_CART_BUY_BUTTONS_NONE: 'Keine',
  PALI_CART_BUY_BUTTONS_DISABLED: 'Zahlungsmethoden deaktiviert',

  PALI_PRODUCT_PLACEMENT_EXTENDED_SHADOWS_NEW_TAB_ADD_TO_CART:
    'Box mit Schatten, Produkte zum Warenkorb hinzufügen wenn möglich, Links im neuen Tab öffnen',
  PALI_PRODUCT_PLACEMENT_EXTENDED_COUPON_CODE_BADGES:
    'Mit Gutschein-Code, mit Badges',
  PALI_PRODUCT_PLACEMENT_EXTENDED_IMAGES_ONLY: 'Nur mit Produktbildern',
  PALI_PRODUCT_PLACEMENT_EXTENDED_IMAGES_AND_DESCRIPRION:
    'Nur mit Produktbildern und Produktinformationen',

  PALI_SEPARATOR_CIRCLE_AND_HORIZONTAL_LINE_PRIMARY:
    'Horizontale Linie mit Kreis, Primärfarbe',
  PALI_SEPARATOR_CIRCLE_AND_HORIZONTAL_LINE_GRAY:
    'Horizontale Linie mit Kreis, Grau',
  PALI_SEPARATOR_DOTTED_LOGO_COLOR_FULL_WIDTH:
    'Gepunktete Linie, Logo-Farbe, volle Breite',
  PALI_SEPARATOR_SOLID_PRIMARY_HALF_WIDTH:
    'Durchgezogene Linie, Primärfarbe, halbe Breite',
  PALI_SEPARATOR_DOTTED_GRAY_CONTAINER_WIDTH:
    'Gepunktete Linie, Grau, Container-Breite',

  PALI_TEXT_WITH_BG_WHITE_BG_CENTERED:
    'Mit weißen Hintergrund and zentriertem Text',
  PALI_TEXT_WITH_BG_LIGHT_BG: 'Mit hellem Hintergrund',
  PALI_TEXT_WITH_BG_THICK_BORDER_LEFT_ALIGNMENT:
    'Mit dickem Rahmen und linksbündigem Text',
  PALI_TEXT_WITH_BG_LIGHT_BG_RIGHT_ALIGNMENT_THIN_BORDER:
    'Mit hellem Hintergrund, rechtbündigem Text und dünnem Rahmen',
  PALI_TEXT_WITH_BG_PRIMARY_LIGHT_BG_THICK_BORDER_ROUNDED:
    'Mit hellem primärfarbenem Hintergrund, dickem Rahmen, runden Ecken, Text in Logo-Farbe und voller Breite',

  PALI_TOPBAR_TEXT_ONLY: 'Nur mit Text',
  PALI_TOPBAR_LINKED_TEXT: 'Mit verlinktem Text',
  PALI_TOPBAR_COUPON_CODE: 'Nur mit Coupon-Code',
  PALI_TOPBAR_SOCIAL_ICONS: 'Nur mit Socialmedia-Icons',
  PALI_TOPBAR_TEXT_SOCIAL_ICONS: 'Mit Text und Socialmedia-Icons',
  PALI_TOPBAR_TEXT_COUPON_CODE: 'Mit Text und Coupon-Code',
  PALI_TOPBAR_COUPON_CODE_SOCIAL_ICONS: 'Mit Coupon-Code und Socialmedia-Icons',
  PALI_TOPBAR_ALL_ITEMS: 'Mit Text, Coupon-Code und Socialmedia-Icons',
  PALI_TOPBAR_TEXT_COUPON_CODE_SOCIAL_ICONS_REVERSED:
    'Mit Text, Coupon-Code und Socialmedia-Icons, umgekehrte Reihenfolge',

  PALI_NOTIFICATION_BANNER_TITLE_ONLY: 'Nur Titel',
  PALI_NOTIFICATION_BANNER_MESSAGE_ONLY: 'Nur Meldung',
  PALI_NOTIFICATION_BANNER_TITLE_AND_MESSAGE: 'Titel und Meldung',
  PALI_NOTIFICATION_BANNER_WITH_ICON: 'Titel, Meldung, Icon',
  PALI_NOTIFICATION_BANNER_ALIGN_CENTER: 'Zentrierter Text',
  PALI_NOTIFICATION_BANNER_ALIGN_CENTER_WITH_ICON: 'Zentrierter Text mit Icon',
  PALI_NOTIFICATION_BANNER_ALIGN_RIGHT: 'Rechtsbündiger Text',
  PALI_NOTIFICATION_BANNER_ALIGN_RIGHT_WITH_ICON:
    'Rechtsbündiger Text mit Icon',
  PALI_NOTIFICATION_BANNER_LIGHT_COLORS: 'Alternatives Farbschema',
  PALI_NOTIFICATION_BANNER_WEIGHT_SEMI_BOLD: 'Halb-fetter Titel',
  PALI_NOTIFICATION_BANNER_WEIGHT_NORMAL: 'Normaler Titel',
  PALI_NOTIFICATION_BANNER_OVERSIZED: 'Übergroßer Inhalt (volle Breite)',
  PALI_NOTIFICATION_BANNER_OVERSIZED_CONTAINER:
    'Übergroßer Inhalt (Container-Breite)',

  PALI_IMAGE_GRID_ROW_CARD_BUTTONS: '1 Zeile, 1 Bild mit 3 Buttons',
  PALI_IMAGE_GRID_TWO_CARDS: '2 verlinkte Bilder, volle Breite',
  PALI_IMAGE_GRID_SMALL_ROW_AND_BG: 'Verkleinerte Zeile mit Hintergrundfarbe',
  PALI_IMAGE_GRID_TOW_ROWS_MAX_CARDS:
    '2 Zeilen, die 2. mit der maximalen Anzahl an Bildern',

  PALI_UNIVERSAL_CARDS_1_CARD_H3: '1 Kachel, H3-Überschrift',
  PALI_UNIVERSAL_CARDS_2_CARDS_NO_BORDER_SHADOW_CORNERS_DARK_HEADLINE_BG_H4:
    '2 Kacheln, Kein Rahmen, kein Box Shadow, keine abgerundeten Ecken, dunkle Überschrift, Hintergrund bg-light, H4-Überschrift',
  PALI_UNIVERSAL_CARDS_3_CARDS_CHANGED_ORDER_ICON:
    '3 Kacheln, geänderte Reihenfolge der Elemente, mit Icon',
  PALI_UNIVERSAL_CARDS_3_PLUS_NO_IMAGE_NO_BUTTON:
    '3+ Kacheln, kein Bild, keine Button',
  PALI_IMAGE_TEXT_1_ROW_H2_LEFT_RIGHT_PRIMARY_TITLE:
    '1 Zeile, H2, Bild - links, linksbündig, primäre Überschrift',
  PALI_IMAGE_TEXT_1_ROW_H3_RIGHT_LEFT: '1 Zeile, H3, Bild - rechts, zentriert',
  PALI_IMAGE_TEXT_2_ROWS_H3_LEFT_RIGHT_DARK_TITLE:
    '2 Zeilen, H3, Bild - links/rechts, dunkle Überschrift',
  PALI_IMAGE_TEXT_4_ROWS_H2_RIGHT_LEFT_NO_PRE_TITLE_ICONS:
    '4 Zeilen, H2, Bild - rechts/links/rechts/links, rechtsbündig, ohne vorausgewähltem Title und Icons',

  PALI_PRODUCT_OFFER_DEFAULT_SINGLE: 'Default, einzelnes Item',
  PALI_PRODUCT_OFFER_ONE_PRODUCT:
    'Einzelnes Produkt mit eckigem Gutschein-Code und CTA-Button',
  PALI_PRODUCT_OFFER_WITH_ALL_FLAGS: 'Mit Schatten und Rahmen',
  PALI_PRODUCT_OFFER_IMAGES_ONLY: 'Nur Bilder und eckige Kanten',

  PALI_CHATCHAMP_BUTTON_BUTTON_ONLY: 'Button ohne alles',
  PALI_CHATCHAMP_BUTTON_WITH_HEADLINE: 'Button mit Überschrift',
  PALI_CHATCHAMP_BUTTON_WITH_PARAGRAPH: 'Button mit Paragraph',
  PALI_CHATCHAMP_BUTTON_WITH_HEADLINE_WITH_PARAGRAPH:
    'Button mit Überschrift und Paragraph',

  PALI_SOCIAL_FEEDS_ALL: 'Alle Beiträge',
  PALI_SOCIAL_FEEDS_ALL_DESCRIPTION: 'Standardverhalten',
  PALI_SOCIAL_FEEDS_SELECTED_POSTS: '9 Beiträge ausgewählt',
  PALI_SOCIAL_FEEDS_SELECTED_POSTS_DESCRIPTION:
    'Die ersten 9 Beiträge ausgewählt, alle Ecken von Buttons und Beiträgen sind abgerundet, Überschriften der Beiträge haben einen transparenten Hintergrund, optionale Beschriftung und große Elemente, anklicken eines Beitrags öffnet einen neuen Tab mit der hinterlegten URL',
  PALI_SOCIAL_FEEDS_SINGLE_POST: 'Einzelner Beitrag',
  PALI_SOCIAL_FEEDS_SINGLE_POST_DESCRIPTION:
    'Einzelner Beitrag, kein Icon, Überschrift, Paragraphen, CTA Button',
  PALI_SOCIAL_FEEDS_SINGLE_POST_CAPTION: 'Einzelner Beitrag mit Beschriftung',
  PALI_SOCIAL_FEEDS_SINGLE_POST_CAPTION_DESCRIPTION:
    'Einzelner Beitrag mit Beschriftung, Überschrift als "h3", Primärfarbe der Überschrift',
  PALI_PAGER_FIRST_PAGE: 'Erste Seite',
  PALI_PAGER_SECOND_PAGE: 'Zweite Seite',
  PALI_PAGER_LAST_PAGE: 'Letzte Seite',
  // pali end

  CUSTOMER_ACCOUNT_CONFIRMATION_FAILED:
    'Bestätigen des Kundenkontos fehlgeschlagen.',
  CUSTOMER_ACCOUNT_CONFIRMED: (name) =>
    `Vielen Dank für die Registrierung bei ${name}. Weiterleitung erfolgt in 5 Sekunden...`,

  PREORDER_CONSENT_ERROR: ({ Link, props }) => [
    'Bitte "reCAPTCHA" in den ',
    <Link key={0} {...props}>
      Privatsphäre-Einstellungen
    </Link>,
    ' aktivieren, um den Artikel vorbestellen zu können.',
  ],
  PREORDER_REQUEST_ERROR:
    'Ein Fehler ist aufgetreten. Bitte zu einem späteren Zeitpunkt erneut versuchen.',
  PREORDER_INVALID_EMAIL:
    'Bei der Eingabe ist ein Fehler aufgetreten. Bitte mit anderer Email-Adresse erneut versuchen.',
  PREORDER_SUCCESSFUL: 'Benachrichtigung aktiviert!',
}
